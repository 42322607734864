import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <div
        className="flex flex-wrap justify-center w-screen py-10 space-y-5 lg:justify-around large:space-y-0"
        id="footer"
      >
        {/* <div className="flex flex-col space-y-2 w-96 sm:w-auto">
          <div className="text-2xl font-f2">Schedule an appointment</div>
          <p>Call (000)-000-0000 for a session</p>
          <p>123 Sunrise Circle, Plymouth MA 02360</p>
          <p>Open Wednesday, Friday, and Saturday, 11-5 pm.</p>
        </div> */}
        <div className="flex flex-col space-y-3">
          <div className="flex justify-center text-2xl uppercase font-f2">
            subscribe
          </div>
          <form action="submit" className="text-black focus:text-black">
            <input
              className="px-4 py-2 rounded-l-full placeholder:text-slate-700 bg-primary6"
              type="email"
              placeholder="Email"
            ></input>
            <button className="px-4 py-[10px] uppercase  rounded-r-full text-gray-200 bg-slate-900">
              submit
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default Footer;
