import React from "react";

class Continuation extends React.Component {
  render() {
    return (
      <div className="flex justify-center w-full max-w-[1200px]" id="resources">
        <div className="p-6 space-y-5">
          <h3 className="tracking-wide font-f2">Recommended Reading:</h3>
          <h4 className="pb-8 font-light font-f2">
            Here are some books that have guided me in my own journey towards
            embodiment.
          </h4>

          <div className="grid grid-cols-2 gap-5 md:grid-cols-3 lg:grid-cols-5">
            <a href="https://thenapministry.com/">
              <img
                className="object-contain max-h-[300px] hover:scale-105 smooth"
                src={require("../assets/images/b1.webp")}
                alt="meaningful alt text to come"
              />
            </a>
            <a href="https://www.sonyareneetaylor.com/books">
              <img
                className="object-contain max-h-[300px] hover:scale-105 smooth"
                src={require("../assets/images/b2.jpg")}
                alt="meaningful alt text to come"
              />
            </a>
            <a href="https://colearthurriley.com/writing/book">
              <img
                className="object-contain max-h-[300px] hover:scale-105 smooth"
                src={require("../assets/images/b3.jpeg")}
                alt="meaningful alt text to come"
              />
            </a>
            <a href="https://marthabeck.com/the-way-of-integrity/">
              <img
                className="object-contain max-h-[300px] hover:scale-105 smooth"
                src={require("../assets/images/b4.jpg")}
                alt="meaningful alt text to come"
              />
            </a>
            <a href="https://untamedbook.com/">
              <img
                className="object-contain max-h-[300px] hover:scale-105 smooth"
                src={require("../assets/images/b5.jpg")}
                alt="meaningful alt text to come"
              />
            </a>
            <a href="https://www.lovelanyadoo.com/shop/astrology-for-real-relationships">
              <img
                className="object-contain max-h-[300px] hover:scale-105 smooth"
                src={require("../assets/images/b6.webp")}
                alt="meaningful alt text to come"
              />
            </a>
            <a href="https://bellhooksbooks.com/product/all-about-love/">
              <img
                className="object-contain max-h-[300px] hover:scale-105 smooth"
                src={require("../assets/images/b7.jpg")}
                alt="meaningful alt text to come"
              />
            </a>
            <a href="https://christyharrison.com/book-anti-diet-intuitive-eating-christy-harrison">
              <img
                className="object-contain max-h-[300px] hover:scale-105 smooth"
                src={require("../assets/images/b8.webp")}
                alt="meaningful alt text to come"
              />
            </a>
            <a href="https://brenebrown.com/book/atlas-of-the-heart/">
              <img
                className="object-contain max-h-[300px] hover:scale-105 smooth"
                src={require("../assets/images/b9.png")}
                alt="meaningful alt text to come"
              />
            </a>
            <a href="https://www.penguinrandomhouse.com/books/592377/burnout-by-emily-nagoski-phd-and-amelia-nagoski-dma/">
              <img
                className="object-contain max-h-[300px] hover:scale-105 smooth"
                src={require("../assets/images/b10.jpeg")}
                alt="meaningful alt text to come"
              />
            </a>
          </div>
          <h4 className="font-light text-center pt-7 font-f2">
            Recommended Listening:
          </h4>
          <div className="flex flex-wrap justify-center gap-10 lg:gap-5 lg:justify-start">
            <a href="https://marthabeck.com/episodes/">
              <img
                className="object-contain max-h-[363px] hover:scale-105 smooth"
                src={require("../assets/images/pod1.jpeg")}
                alt="meaningful alt text to come"
              />
            </a>
            <a href="http://wecandohardthingspodcast.com/">
              <img
                className="object-contain max-h-[363px] hover:scale-105 smooth"
                src={require("../assets/images/pod2.jpeg")}
                alt="meaningful alt text to come"
              />
            </a>
            <a href="https://www.lovelanyadoo.com/ghost-of-a-podcast">
              <img
                className="object-contain max-h-[363px] hover:scale-105 smooth"
                src={require("../assets/images/pod3.jpg")}
                alt="meaningful alt text to come"
              />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Continuation;
