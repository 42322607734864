import React from "react";

// About section
class About extends React.Component {
  render() {
    return (
      <div
        className="flex pt-10 justify-center w-full max-w-[1200px] flex-wrap"
        id="about"
      >
        <div className="p-2 lg:p-6">
          <div className="flex flex-wrap justify-center pb-10 lg:space-x-10 lg:flex-nowrap">
            {/* images */}
            <div className="lg:max-w-[40%] flex place-items-center flex-col space-y-5 lg:p-0 pb-10">
              <h3 className="pb-5 tracking-wide font-f2">
                About the Practitioner
              </h3>
              <img
                className="object-contain rounded-tl-full rounded-tr-full"
                src={require("../assets/images/profile.jpeg")}
                alt="meaningful alt text to come"
              />
              <img
                className="object-contain rounded-bl-full rounded-br-full"
                src={require("../assets/images/woods.jpeg")}
                alt="meaningful alt text to come"
              />
            </div>
            {/* text */}
            <div className="lg:w-[40%] px-5 sm:text-[18px] text-[15px]">
              <p className="indent-9 sm:text-[18px] text-[15px]">
                Hello, I am so glad you’re here! My name is Hayley Sabella, LMT.
                I am a licensed massage therapist, death doula, musician,
                astrology nerd and garden enthusiast. While my background is
                quite varied- English literature, sociology, songwriting &
                performance, organic farming, elder care- my primary passion is
                healing. This passion for healing led me to study therapeutic
                massage at Spa Tech Institute and to become certified as an end
                of life doula with Going with Grace.
              </p>

              <p className="indent-9 sm:text-[18px] text-[15px]">
                Life can be hard sometimes! We all must cope with our own share
                of stress, tension, and injury. But it’s never too late to take
                steps towards a little more comfort and balance. Taking good
                care of our physical bodies is an essential component of the
                healing process, and finding the right massage therapist for you
                is a game changer. Whether you are simply dealing with the
                pressure of managing daily stress, or you are in the thick of
                major life changes, massage therapy is a powerful modality for
                embodiment and becoming more whole. I hope you book a session
                with me so we can find out if we’re the right match! Let’s heal
                together.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
