import React from "react";

class First extends React.Component {
  render() {
    return (
      <div className="flex justify-center max-w-[1200px]">
        <div className="p-6 space-y-5">
          <h4 className="tracking-wide font-f2">
            First time guest? Here’s what you can expect:
          </h4>
          <p className="text-lg">
            We’re so thrilled that you’ve chosen to take this powerful step in
            your healing journey! Please arrive 10-15 minutes prior to the
            scheduled appointment. Get comfortable right away in one of our cozy
            chairs with a complimentary locally sourced flower-petal, epsom-salt
            foot soak. While you begin to unwind, we will make sure your
            paperwork is accurate and up to date so we can get to know you and
            prepare to meet your unique needs.
          </p>
        </div>
      </div>
    );
  }
}

export default First;
