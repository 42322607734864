import React from "react";
import Enhancements from "./Enhancements";

class Menu extends React.Component {
  render() {
    return (
      <div
        className="flex flex-col w-full px-10 py-10 mb-2 text-gray-900 lg:py-24"
        id="services"
      >
        <h3 className="flex justify-center pb-10 font-f2">
          Offerings for at-home treatments
        </h3>

        <div className="flex flex-col pb-10 space-y-1 sm:space-y-0">
          <h4 className="font-f2">Rest & Recover</h4>
          <div className="w-full text-sm font-semibold sm:text-right sm:-translate-y-8 sm:text-md">
            <p>60 mins/$130</p>
            <p>90 mins/$185</p>
          </div>
          <p className="text-sm sm:-translate-y-5 sm:text-md md:text-lg">
            A grounding relaxation massage designed to ease tension, soothe your
            nervous system and help you unwind. This restful Swedish style
            massage may also include hydrotherapy, aromatherapy and gentle
            stretching.
          </p>
        </div>
        <div className="flex flex-col pb-10 space-y-1 sm:space-y-0">
          <h4 className="font-f2">Inspire & Align</h4>
          <div className="w-full text-sm font-semibold sm:text-right sm:-translate-y-8 sm:text-md">
            <p>60 mins/$130</p>
            <p>90 mins/$185</p>
          </div>
          <p className="text-sm sm:-translate-y-5 sm:text-md md:text-lg">
            Need a reset? Get back in touch with your sense of purpose: this
            treatment may include Swedish, crystal healing, cupping and
            occasional deep pressure to help remove any blockages and get you
            feeling like yourself again.
          </p>
        </div>
        <div className="flex flex-col space-y-1 sm:space-y-0">
          <h4 className="font-f2">Face the Pain</h4>
          <div className="w-full text-sm font-semibold sm:text-right sm:-translate-y-8 sm:text-md">
            <p>60 mins/$145</p>
            <p>90 mins/$200</p>
          </div>
          <p className="pb-10 text-sm sm:-translate-y-5 sm:text-md md:text-lg">
            Sometimes the assignment is simple: HELP ME STOP HURTING. This deep
            tissue massage targets areas of tension and may use a combination of
            hot stones, positional release, stretching and hydrotherapy. *90 min
            session recommended
          </p>
        </div>

        <Enhancements />

        <a
          id="book"
          className="flex justify-center"
          href="https://calendly.com/silvercord"
        >
          <button className="p-6 px-12 text-4xl uppercase transition-colors ease-in-out bg-gray-900 rounded-full duration-400 font-f2 hover:bg-purple-400 text-slate-100">
            book a session
          </button>
        </a>
      </div>
    );
  }
}

export default Menu;
