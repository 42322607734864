import "./assets/index.css";
import { HashLink as Link } from "react-router-hash-link";
import Footer from "./components/Footer";
import Mission from "./components/Mission";

import First from "./components/First";
import Menu from "./components/Menu";
// import Cancellation from "./components/Cancellation";

import About from "./components/About";
import Continuation from "./components/Continuation";

function App() {
  return (
    <div className="flex flex-col justify-center w-screen md:space-y-5 bg-slate-800 place-items-center text-primary6">
      <nav className="flex justify-center py-8 pb-10 md:gap-24 lg:justify-evenly place-items-center">
        <div className="flex flex-col space-y-2">
          <Link
            smooth
            to="#services"
            className="hidden text-xl tracking-wide text-purple-300 uppercase cursor-pointer font-f2 hover:text-orange-300 smooth lg:flex"
          >
            massage therapy | death work | community care
          </Link>
        </div>

        <div className="hover:-translate-y-[1px] hidden smooth cursor-pointer lg:flex flex-col justify-center place-items-center">
          <img
            className="-translate-y-5 translate-x-5  w-[420px] hidden lg:inline"
            src={require("./assets/images/silvercord.png")}
            alt="Profile of the practitioner"
          />
        </div>

        <div className="space-x-5 text-xl tracking-wide text-purple-300 font-f2 text-primary3">
          <img
            className="flex lg:hidden w-[75]"
            src={require("./assets/images/silvercord.png")}
            alt="Profile of the practitioner"
          />

          <Link className="hover:text-orange-300 smooth" smooth to="#about">
            About
          </Link>
          <Link
            smooth
            to="#services"
            className="hover:text-orange-300 smooth"
            href="/"
          >
            Services
          </Link>
          <Link
            smooth
            to="#book"
            className="hover:text-orange-300 smooth"
            href="/"
          >
            Booking
          </Link>
          <Link
            smooth
            to="#resources"
            className="hover:text-orange-300 smooth"
            href="/"
          >
            Resources
          </Link>
        </div>
      </nav>

      <Mission></Mission>

      {/* <First></First> */}

      <div className="space-y-5 max-w-[1000px] bg-primary6 rounded-sm lg:mx-0 my-16">
        <Menu></Menu>
      </div>

      <About></About>

      <Continuation></Continuation>

      <Footer></Footer>
    </div>
  );
}

export default App;
