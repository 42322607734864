import React from "react";

class Enhancements extends React.Component {
  render() {
    return (
      <div className="flex flex-col justify-between w-full mb-24">
        <h4 className="pb-2 font-f2">Optional Enhancements</h4>

        <div className="flex flex-wrap justify-between w-full">
          <h4 className="text-base sm:text-lg font-f2">CBD $10</h4>
          <h4 className="text-base sm:text-lg font-f2">
            Local Arnica salve $15
          </h4>
          <h4 className="text-base sm:text-lg font-f2">Hot Stone $20</h4>
        </div>
        <div className="flex flex-col p-0 m-0 mt-4 space-y-0">
          <p className="text-[10px] font-f1">
            *** Depending on your location, mileage fees may apply.
          </p>
          <p className="text-[10px] font-f1">
            *** Have your own massage table? Apply a $15 discount to the rates
            above
          </p>
        </div>
      </div>
    );
  }
}

export default Enhancements;
