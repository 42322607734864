import React from "react";

class Mission extends React.Component {
  render() {
    return (
      <div className="flex justify-center lg:max-w-[1200px] md:pb-10">
        <div className="p-6 space-y-4">
          <h3 className="tracking-wide font-f2 ">Mission Statement</h3>
          <h4 className="text-xl -translate-y-3 font-f2">
            Reconnecting with the wisdom of the body
          </h4>
          <p className="text-lg">
            At Silver Cord, we believe in the wisdom of the body; by offering
            healing touch, we facilitate bodily reconnection, which has
            cascading effects not only on the individual, but also within the
            community and environment. Our mission is to foster more creativity,
            wholeness, and interconnection, thereby creating a more equitable,
            beautiful, compassionate and embodied community. Let’s heal
            together!
          </p>
        </div>
      </div>
    );
  }
}

export default Mission;
